// TEMPLATE - copy to src/firebase/config.js and modify accordingly

import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  // connectFirestoreEmulator // COMMENT TO DEPLOY
} from 'firebase/firestore';
import {
  getAuth,
  // connectAuthEmulator // COMMENT TO DEPLOY
} from 'firebase/auth';

// init firebase - Copy the values from the Project Settings page on the Firebase project's console (and create WEB app with firebase hosting, if not done yet)
initializeApp({
  apiKey: "AIzaSyDjFqM29BovYqypZv9RqmjudOl6_t6CUJQ",
  authDomain: "holipay-dev.firebaseapp.com",
  projectId: "holipay-dev",
  storageBucket: "holipay-dev.appspot.com",
  messagingSenderId: "596285702457",
  appId: "1:596285702457:web:8f3de7274ea2e7dfe6e8e8",
  measurementId: "G-GJS90PBCF4"
});
// init services
const db = getFirestore();
const auth = getAuth();

// use emulator -- COMMENT TO DEPLOY
// connectFirestoreEmulator(db, 'localhost', 8080);
// connectAuthEmulator(auth, 'http://localhost:9099');

export { db, auth }
